import * as React from 'react'
import { AppBar } from '../header/AppBar'
import { AppBarForMobile } from '../header/AppBarForMobile'
import { Footer } from './Footer'
import { useUser } from '../../contexts/AuthContext'
import { css } from '@emotion/react'
import { mq } from 'plume-ui'

type Props = {
  children: React.ReactNode
  className?: string
}

export const Template: React.FC<Props> = ({ ...props }) => {
  const user = useUser()
  return (
    <>
      <header>
        <div
          css={css`
            ${mq.untilMedium} {
              display: none;
            }
          `}
        >
          <AppBar user={user} />
        </div>
        <div
          css={css`
            ${mq.large} {
              display: none;
            }
          `}
        >
          <AppBarForMobile />
        </div>
      </header>
      <main className={props.className}>{props.children}</main>
      <Footer />
    </>
  )
}
